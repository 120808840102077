/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

input {
  overflow: visible;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

:is([type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

menu {
  display: block;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Syne;
  font-weight: 400;
  font-style: normal;
  src: url("Syne-Regular.2a3e3af5.eot") format("embedded-opentype"), url("Syne-Regular.79bdd1a4.woff2") format("woff2"), url("Syne-Regular.43586140.woff") format("woff"), url("Syne-Regular.448473de.ttf") format("truetype");
}

@font-face {
  font-family: Syne;
  font-weight: 700;
  font-style: normal;
  src: url("Syne-Bold.c1126987.eot") format("embedded-opentype"), url("Syne-Bold.b9035cbe.woff2") format("woff2"), url("Syne-Bold.32a171ba.woff") format("woff"), url("Syne-Bold.c53bbd06.ttf") format("truetype");
}

@font-face {
  font-family: Syne;
  font-weight: 800;
  font-style: normal;
  src: url("Syne-Extra.cc7503a3.eot") format("embedded-opentype"), url("Syne-Extra.095d1ccd.woff2") format("woff2"), url("Syne-Extra.34d14e0c.woff") format("woff"), url("Syne-Extra.bb6f5b89.ttf") format("truetype");
}

html {
  --espresso: #483308;
  --capuccino: #733a1d;
  --macchiato: #a54e2a;
  --latte: #f0ede9;
  font-family: Syne, sans-serif;
  font-size: 16px;
}

body {
  background: url("32784-8-coffee-mug-top.e872cda1.png") right -5vw bottom -2vw / 25vw no-repeat, url("purty-wood_2.7894d25e.png"), #fff;
}

h1 {
  color: #483308;
  margin: 1rem 0 2rem;
  font-size: 2.5rem;
  font-weight: 800;
}

p {
  margin: 0;
  font-size: 1rem;
}

a {
  color: #733a1d;
  text-decoration: none;
}

a:hover {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
}

ul {
  list-style: none;
}

footer {
  border-top: 2px solid #a54e2a;
  justify-content: space-around;
  margin-top: 1rem;
  padding: .5rem;
  display: flex;
}

footer svg {
  fill: #483308;
  stroke: #483308;
  width: 1.5rem;
}

#bio {
  display: flex;
}

#bio .img-container {
  margin: 1rem;
}

#bio .headshot {
  border-radius: 50%;
  width: 15vw;
  min-width: 12rem;
  height: 15vw;
  min-height: 12rem;
  overflow: hidden;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

#bio .headshot > img {
  width: 100%;
}

.ep-paper {
  background-color: #f0ede9;
  border-radius: 16px;
  max-width: 1024px;
  margin: 2rem auto;
  padding: 2rem;
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
}

.icon {
  width: 5rem;
}

.icon > svg {
  fill: #483308;
  stroke: #483308;
  width: 100%;
}

.grouping {
  border-top: 2px solid #a54e2a;
  grid-template: "head head" 2rem
                 "icon text"
                 / 6rem;
  margin-top: 1rem;
  padding: .5rem;
  display: grid;
}

.grouping h3 {
  color: #733a1d;
  grid-area: head;
  margin: 0;
  font-size: 1.5rem;
  line-height: 2rem;
}

.grouping h4 {
  color: #483308;
  margin: 1rem 0 .5rem;
  font-size: 1.25rem;
}

.grouping ul {
  grid-area: text;
  padding: 0 2.5rem;
}

.grouping li:first-of-type h4 {
  margin-top: 0;
}

.grouping .icon {
  grid-area: icon;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
}

.grouping .dates, .grouping .stack {
  color: #a54e2a;
  font-weight: bold;
}

.grouping .description {
  text-align: justify;
}

.print-only {
  display: none;
}

.credits h5 {
  color: #733a1d;
  cursor: pointer;
  margin: 1rem;
}

.credits dl {
  position: fixed;
  bottom: 0;
  left: 0;
}

.credits .hide {
  display: none;
}

.credits .close {
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: .5rem;
  right: 1.5rem;
}

@media screen and (width <= 599px) {
  body {
    background: url("32784-8-coffee-mug-top.e872cda1.png") right -5vw bottom -2vw / 50vw no-repeat, url("purty-wood_2.7894d25e.png"), #fff;
  }

  #resume {
    margin: 2rem 1rem 10rem;
  }

  #bio {
    flex-direction: column;
  }

  #bio .text {
    text-align: center;
    order: 2;
  }

  #bio .img-container {
    order: 1;
  }

  #bio .img-container .headshot {
    margin: 0 auto;
  }

  .grouping {
    grid-template-columns: auto;
    grid-template-areas: "head"
                         "icon"
                         "text";
  }

  .grouping .icon {
    height: 8rem;
  }

  .grouping .icon > svg {
    height: 100%;
  }

  .grouping ul {
    padding: 0;
  }
}

@media print {
  html {
    font-size: 10pt;
  }

  @page {
    margin: 0;
  }

  body {
    margin: .5in;
  }

  h1 {
    margin: 0 0 1rem;
  }

  #bio {
    margin-bottom: .5rem;
  }

  #bio .headshot {
    width: 10rem;
    min-width: 10rem;
    height: 10rem;
    min-height: 10rem;
  }

  #bio .img-container {
    margin: 0 0 0 1rem;
  }

  .ep-paper {
    margin: 0;
    padding: 0;
    box-shadow: none !important;
  }

  .credits {
    display: none;
  }

  .print-only {
    display: initial;
  }

  .grouping {
    grid-template: "head"
                   "text"
                   / auto;
    margin: 0;
    position: relative;
  }

  .grouping h3 {
    line-height: 3rem;
  }

  .grouping h4 {
    margin: .5rem 0 0;
  }

  .grouping ul {
    margin: 0;
    padding: 0 2rem;
  }

  .grouping .icon {
    height: 10rem;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .grouping .icon object[type="image/svg+xml"] {
    fill: red;
  }

  .grouping .icon > svg {
    opacity: .2;
    fill: #a74f2b;
    stroke: #a54e2a;
    height: 100%;
  }

  .grouping .print-hidden {
    display: none;
  }
}

/*# sourceMappingURL=index.41824821.css.map */
